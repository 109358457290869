<template>
  <section id="settingsSeo">
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('resFunciones.awardSystemActive.title') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <b-overlay
                  variant="transparent"
                  :show="show"
                >
                  <b-form @submit.prevent="updateAward">
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('awardSystem.goldAward')"
                        >
                          <b-form-input
                            v-model="goldAward"
                            placeholder="0"
                            type="number"
                            maxlength="80"
                            class="mr-1"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('awardSystem.silverAward')"
                        >
                          <b-form-input
                            v-model="silverAward"
                            placeholder="0"
                            class="mr-1"
                            maxlength="180"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('awardSystem.bronzeAward')"
                        >
                          <b-form-input
                            v-model="bronzeAward"
                            placeholder="0"
                            class="mr-1"
                            maxlength="180"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{
                            $t("dataGeneric.save")
                          }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormInput,
  BOverlay,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'

import { showToast, messageError } from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BForm,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      silverAward: null,
      goldAward: null,
      bronzeAward: null,
      show: false,
      id: null,

    }
  },

  mounted() {
    this.getClient()
  },
  methods: {
    getClient() {
      const userData = getUserData()
      axios
        .post('', {
          query: `query{
            allClients(clientId:"${userData.profile.client.clientId}"){
              edges {
                node {
                  id
                  goldAward
                  silverAward
                  bronzeAward
                }
              }
            }
          }
`,
        })
        .then(result => {
          messageError(result, this)

          const response = result.data.data.allClients.edges[0]
          this.id = response.node.id
          this.goldAward = response.node.goldAward
          this.silverAward = response.node.silverAward
          this.bronzeAward = response.node.bronzeAward
        })
        .catch(err => {
          console.log(err)
        })
    },

    updateAward(event) {
      this.show = true
      event.preventDefault()
      const query = `
            mutation{
            updateClient(id:"${this.id}",input:{
              silverAward:${this.silverAward},
              bronzeAward:${this.bronzeAward},
              goldAward:${this.goldAward},            

            }) {
              client{
                name
                id
              }
            }
          }
        `

      axios.post('', { query })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)

          this.show = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>
