<template id="badge-view">
  <form
    ref="form"
    @submit.prevent="handleOk"
  >
    <b-row>
      <b-col
        v-if="id == null"
        md="10"
        style="text-align: center;"
      >
        <b-alert show>
          <small>{{ $t('infoCreBadg') }}</small>
        </b-alert>
      </b-col>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="id == null"
    >
      <b-col>
        <b-alert
          class="p-1 mt-2"
          variant="warning"

          show
        >
          <small>{{ $t('infoCreBadg') }}</small>
        </b-alert>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="9">
        <b-form-group
          :label="$t('firstName') + ' *'"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :placeholder="$t('firstName')"
            :state="nameState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="language == base"
        style="align-self: center;"
        md="3"
      >
        <b-form-checkbox
          v-model="isActive"
          switch
        >
          {{ $t('dataGeneric.activeQ') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="language == base">
      <b-col>
        <b-form-group
          :label="$t('branding.labelColor')"
          class="mb-2
                                    mr-1
                                    embed-responsive embed-responsive-16by9"
        >
          <div
            class="base-color-input embed-responsive-item"
            :style="{
              'background-color': `${backgroundColor}`
            }"
            @click="chooseInput('backgroundColor')"
          >
            <span>{{ backgroundColor }}</span>
            <input
              id="backgroundColor"
              v-model="backgroundColor"
              class="inputColor"
              type="color"
            >
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('branding.textColor')"
          class="
                mb-2
                mr-1
                embed-responsive embed-responsive-16by9
                "
        >
          <div
            class="base-color-input embed-responsive-item"
            :style="{
              'background-color': `${textColor}`
            }"
            @click="chooseInput('textColor')"
          >
            <span>{{ textColor }}</span>
            <input
              id="textColor"
              v-model="textColor"
              class="inputColor"
              type="color"
            >
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="language == base">
      <image-element-vue
        :image-origin="imageFile"
        :media-origin-selected="'BUK'"
        :image-type="'imageFile'"
        :md-length="6"
        :label="$t('Image')"
        :can-delete="imageBol ? true : false"
        @deleteImage="deleteImage"
        @saveImage="loadImage"
      />
    </b-row>
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
  BButton,
  BFormGroup,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, isEmpty } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    ImageElementVue,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BAlert,
    BFormInput,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    id: { default: null },
    base: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    createBool: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      badges: null,
      show: false,
      name: '',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      userData: getUserData(),
      nameState: null,
      isActive: true,
      imageBol: false,
      imageFile: null,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getBadge()
  },
  methods: {
    getBadge() {
      const { headers } = this
      axios
        .post('', {
          query: `
                    {
                        allBadge(id:"${this.id}")
                        {
                        edges
                        {
                            node
                            {
                                    id
                                    name(lang:"${this.language}")
                                    isActive
                                    backgroundColor
                                    textColor
                                    image
                                }
                            }
                        }
                    }
                    `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.name = res.data.data.allBadge.edges[0].node.name
          this.isActive = res.data.data.allBadge.edges[0].node.isActive
          this.backgroundColor = res.data.data.allBadge.edges[0].node.backgroundColor
          this.textColor = res.data.data.allBadge.edges[0].node.textColor
          this.imageFile = res.data.data.allBadge.edges[0].node.image
          this.imageBol = !isEmpty(this.imageFile)

          this.show = false
        })
        .catch(() => {
          this.show = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageFile':
          this.imageFile = data
          break
        default:
          break
      }
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    handleOk() {
      if (this.id !== null) { this.editBadge() } else this.createBadge()
    },
    createBadge() {
      if (!this.checkFormValidity()) {
        return
      }
      const { headers } = this
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
            mutation{
              createBadge(input:{name:"${this.name}",client:"${userData.profile.client.id}",backgroundColor:"${this.backgroundColor}",textColor:"${this.textColor}",isActive:${this.isActive}}){
                badge {
                  id
                }
              }
            }
          `
      data.append('query', query)
      data.append('image', this.imageFile)
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()

            const query = `
                mutation{
                  updateBadge(id:"${this.id}",input:{
                  }){
                    badge{
                          id
                        
                        }
                      }
                    }
              `
            data.append('query', query)
            switch (type) {
              case 'imageFile':
                this.imageBol = false
                this.imageFile = null
                data.append('deleteFile', 'image')
                break

              default:
                break
            }

            axios.post('', data, config).then(createResponse => {
              messageError(createResponse, this)
              this.showDesign = false
              this.showMosca = false
              this.showFondos = false
              this.showLogos = false

              showToast(this.$t('success'), 1, this)
            }).catch(() => {
              this.showDesign = false

              showToast(this.$t('code.updateDataError'), 2, this)
            })
          }
        })
        .catch(() => { })
    },
    editBadge() {
      const { headers } = this

      if (!this.checkFormValidity()) {
        return
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: this.name, type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'backgroundColor', value: this.backgroundColor, type: 'String' },
          { title: 'textColor', value: this.textColor, type: 'String' },
          { title: 'isActive', value: this.isActive, type: 'Boolean' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateBadge(id:"${this.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
                badge {
                   id
                 }
                }
            }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.imageFile)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },

  },
}
</script>

<style lang="scss" scoped>
#badge-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#badge-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#badge-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#badge-view .card-body h4 {
  font-size: 1.286rem !important;
}

#badge-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#badge-view .fly-image-input {
  max-width: 100%;
}

#badge-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#badge-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#badge-view .div {
  display: inline-block;
}

#badge-view .inputColor {
  visibility: hidden;
}

#badge-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#badge-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#badge-view .file-input {
  display: none;
}

#badge-view .cBox {
  align-items: center;
}

#badge-view .custom-control-label {
  font-size: 15px;
}

#badge-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#badge-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#badge-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>
