<template>
  <section id="platform-settings-bucket">
    <!-- Bucket configuration -->
    <b-row class="match-height mt-2">
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              {{ $t('Statistic') }}
              <feather-icon icon="InfoIcon" />
            </b-card-title>
          </b-card-header>
          <b-row>
            <b-col
              lg="10"
              class="ml-2 mb-2"
            >
              <!-- Bucket type -->

              <div id="tabsBucketType">
                <b-tabs>
                  <countly-analytics />
                  <adobe-analytics />
                  <ga4-analytics />
                </b-tabs>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BTabs,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import AdobeAnalytics from './analytics/AdobeAnalytics.vue'
import CountlyAnalytics from './analytics/CountlyAnalytics.vue'
import Ga4Analytics from './analytics/Ga4Analytics.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    AdobeAnalytics,
    CountlyAnalytics,
    Ga4Analytics,
    BCardTitle,
    BCardHeader,
    BTabs,

  },

  directives: {
    Ripple,
  },
  data() {
    return {

    }
  },

}

</script>

<style lang="scss" scoped>
.nav-tabs .nav-link:after{
  background: linear-gradient(30deg, #fc1515, rgb(250 93 99)) !important;
}
</style>
