<template>
  <section id="settingsConfig">
    <b-row class="match-height mt-2">
      <b-col>
        <!-- Payments Config -->
        <b-card
          v-if="checkPermissions('frontend_config.menu_cms_paymentsconfig')"
        >
          <b-card-header>
            <b-card-title>{{ $t("frontendConfig.paymentsConfig") }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              :show="loadingPayments"
              variant="transparent"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
            >
              <b-form @submit.prevent="savePaymentsConfig">
                <b-row>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.blockPaymentCat')">
                      <b-form-checkbox
                        v-model="paymentsConfig.blockPaymentCategories"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.blockPaymentCont')">
                      <b-form-checkbox
                        v-model="paymentsConfig.blockPaymentContents"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.allowSessionCodes')">
                      <b-form-checkbox
                        v-model="paymentsConfig.canUseSessionCodes"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.enablePayments')">
                      <b-form-checkbox
                        v-model="paymentsConfig.canUsePayments"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-overlay>
          </b-card-body>
        </b-card>

        <!-- NavBar Config -->
        <b-card
          v-if="checkPermissions('frontend_config.menu_cms_navbarconfig')"
          class="mt-3"
        >
          <b-card-header>
            <b-card-title>{{ $t("frontendConfig.navbarConfig") }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              :show="loadingNavBar"
              variant="transparent"
              spinner-variant="primary"
              blur="0"
              opacity=".75"
              rounded="sm"
            >
              <b-form @submit.prevent="saveNavBarConfig">
                <b-row>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.hasSearch')">
                      <b-form-checkbox
                        v-model="navBarConfig.hasSearch"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.hasLoginLink')">
                      <b-form-checkbox
                        v-model="navBarConfig.hasLoginLink"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('frontendConfig.hasRegisterLink')">
                      <b-form-checkbox
                        v-model="navBarConfig.hasRegisterLink"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  type="submit"
                  variant="success"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { showToast, b64ToUtf8, checkPermissions } from '@/store/functions'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      clientId: null,
      paymentsConfig: {
        id: null,
        blockPaymentCategories: false,
        blockPaymentContents: false,
        canUseSessionCodes: false,
        canUsePayments: true,
      },
      navBarConfig: {
        id: null,
        hasSearch: true,
        hasLoginLink: true,
        hasRegisterLink: true,
      },
      loadingPayments: false,
      loadingNavBar: false,
    }
  },
  mounted() {
    const [, clientId] = b64ToUtf8(getUserData().profile.client.id).split(':')
    this.clientId = clientId
    this.fetchConfigurations()
  },
  methods: {
    checkPermissions,
    async fetchConfigurations() {
      this.loadingPayments = true
      this.loadingNavBar = true

      const query = `
        query {
          allPaymentsConfigs(client: "${this.clientId}") {
            edges {
              node {
                id
                blockPaymentCategories
                blockPaymentContents
                canUseSessionCodes
                canUsePayments
              }
            }
          }
          allNavbarConfigs(client: "${this.clientId}") {
            edges {
              node {
                id
                hasSearch
                hasLoginLink
                hasRegisterLink
              }
            }
          }
        }
      `

      try {
        const response = await axios.post('', { query })
        const { data } = response.data

        if (data.allPaymentsConfigs.edges.length > 0) {
          this.paymentsConfig = { ...data.allPaymentsConfigs.edges[0].node }
        } else {
          await this.createPaymentsConfig()
        }

        if (data.allNavbarConfigs.edges.length > 0) {
          this.navBarConfig = { ...data.allNavbarConfigs.edges[0].node }
        } else {
          await this.createNavBarConfig()
        }

        this.loadingPayments = false
        this.loadingNavBar = false
      } catch (error) {
        console.error(error)
        this.loadingPayments = false
        this.loadingNavBar = false
      }
    },

    async createPaymentsConfig() {
      const mutation = `
        mutation {
          createPaymentsConfig(input: {
            client: "${getUserData().profile.client.id}"
            blockPaymentCategories: false
            blockPaymentContents: false
            canUseSessionCodes: false
            canUsePayments: true
          }) {
            paymentsConfig {
              id
            }
          }
        }
      `

      await axios.post('', { query: mutation })
      this.fetchConfigurations()
    },

    async createNavBarConfig() {
      const mutation = `
        mutation {
          createNavbarConfig(input: {
            client: "${getUserData().profile.client.id}"
            hasSearch: true
            hasLoginLink: true
            hasRegisterLink: true
          }) {
            navBarConfig {
              id
            }
          }
        }
      `

      await axios.post('', { query: mutation })
      this.fetchConfigurations()
    },

    async savePaymentsConfig() {
      this.loadingPayments = true
      const mutation = `
        mutation {
          updatePaymentsConfig(id: "${this.paymentsConfig.id}", input: {
            blockPaymentCategories: ${this.paymentsConfig.blockPaymentCategories}
            blockPaymentContents: ${this.paymentsConfig.blockPaymentContents}
            canUseSessionCodes: ${this.paymentsConfig.canUseSessionCodes}
            canUsePayments: ${this.paymentsConfig.canUsePayments}
          }) {
            paymentsConfig { id }
          }
        }
      `

      await axios.post('', { query: mutation })
      showToast(this.$t('dataGeneric.success'), 1, this)
      this.loadingPayments = false
    },

    async saveNavBarConfig() {
      this.loadingNavBar = true
      const mutation = `
        mutation {
          updateNavbarConfig(id: "${this.navBarConfig.id}", input: {
            hasSearch: ${this.navBarConfig.hasSearch}
            hasLoginLink: ${this.navBarConfig.hasLoginLink}
            hasRegisterLink: ${this.navBarConfig.hasRegisterLink}
          }) {
            navBarConfig { id }
          }
        }
      `

      await axios.post('', { query: mutation })
      showToast(this.$t('dataGeneric.success'), 1, this)
      this.loadingNavBar = false
    },
  },
}
</script>

<style scoped></style>
