<template>
  <section id="platform-settings">
    <!-- Política de privacidad -->
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <b-row>
        <b-col>
          <h1>{{ $t("footerLink.title") }}</h1>
          <h5 class="text-primary">
            {{ $t("footerLink.sub") }}
          </h5>
        </b-col>
      </b-row>
      <b-card class="mt-2">
        <b-row
          class="justify-content-end mb-2"
        >
          <b-button
            variant="primary"
            @click="openModal('create')"
          >
            {{ $t('footerLink.create') }}
          </b-button>
        </b-row>
        <div v-if="!show">
          <b-table
            :items="allFooters"
            :fields="fields"
            striped
            responsive
          >
            <template #cell(label)="data">
              <div class="">
                <strong>
                  {{ data.item[`label${language}`] }}
                </strong>
              </div>
            </template>
            <template #cell(link)="data">
              <div v-if="data.item[`link${language}`]">
                {{ removeMailto(data.item[`link${language}`]) }}
              </div>
            </template>
            <template #cell(friendly-url)="data">
              <div v-if="data.item[`friendlyUrl${language}`]">
                {{ data.item[`friendlyUrl${language}`] }}
              </div>
            </template>
            <template #cell(html)="data">
              <b-button
                variant="outline-warning"
                :disabled="!data.item[`html${language}`]"
                @click="previewFooter(data.item[`html${language}`])"
              >
                {{ $t("dataGeneric.preview") }}
              </b-button>
            </template>
            <template #cell(type)="data">
              <div v-if="data.item.type">
                {{ getFooterType(data.item.type, data.item[`link${language}`]) }}
              </div>
            </template>
            <template #cell(active)="data">
              <div class="text-nowrap ">
                <h4>
                  <b-badge
                    pill
                    :variant="`light-${data.item.isActive ? 'success' : 'danger'}`"
                    class="text-capitalize"
                  >
                    <feather-icon
                      :icon="data.item.isActive ? 'CheckIcon' : 'XIcon'"
                      size="20"
                      class="mr-50"
                    />
                    {{
                      data.item.isActive
                        ? $t("dataGeneric.active")
                        : $t("dataGeneric.inactive")
                    }}
                  </b-badge>
                </h4>
              </div>
            </template>
            <template #cell(edit)="data">
              <div class="d-flex align-items-center">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  dropleft
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click.prevent="editFooter(data.item)">
                    <div class="d-flex">
                      <feather-icon
                        icon="EditIcon"
                        class="text-success"
                        size="15"
                      />
                      <span class="align-middle ml-50">
                        {{ $t('Edit') }}
                      </span>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="confirmDeleteFooter(data.item)">
                    <div class="d-flex">
                      <feather-icon
                        icon="Trash2Icon"
                        class="text-danger mb-50"
                        size="15"
                      />
                      <span class="align-middle ml-50">
                        {{ $t('dataGeneric.delete') }}
                      </span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      ref="preview-modal"
      hide-footer
      :title="$t('code.prev')"
    >
      <html-modal :msg="previewBody" />
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal('preview')"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="create-footer-modal"
      size="lg"
      hide-footer
      :title="$t('footerLink.name')"
    >
      <footer-link-modal
        :selected-footer-link="selectedFooter"
        :language="language"
        @save-changes="getFooterLinks()"
        @close-modal="hideModal('create')"
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BOverlay,
  BButton,
  BModal,
  BRow,
  BCol,
  BCard,
  BBadge,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast, isEmpty } from '@/store/functions'
import HtmlModal from '@/views/common/HtmlModal.vue'

import { getUserData } from '@/auth/utils'
import FooterLinkModal from '@/views/common/FooterLinkModal.vue'

export default {
  components: {
    BOverlay,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    HtmlModal,
    FooterLinkModal,
  },
  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        { key: 'label', label: this.$t('footerLink.label') },
        { key: 'link', label: this.$t('link') },
        { key: 'friendly-url', label: this.$t('friendUrl') },
        { key: 'html', label: 'HTML' },
        { key: 'type', label: this.$t('footerLink.type') },
        { key: 'active', label: this.$t('editContent.status') },
        { key: 'edit', label: this.$t('resources.tableHeader.actions') },
      ],
      userData: getUserData(),
      show: false,
      previewBody: null,
      selectedFooter: null,
      allFooters: null,
    }
  },
  mounted() {
    this.getFooterLinks()
  },
  methods: {
    getFooterLinks() {
      this.show = true
      const query = `
        query {
          allFooterLinks (client: "${this.userData.profile.client.id}"){
            edges{
              node{
                id
                label${this.language}
                link${this.language}
                html${this.language}
                friendlyUrl${this.language}
                type
                isActive
              }  
            }
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)
        const dataRes = res.data.data.allFooterLinks.edges
        this.allFooters = dataRes.map(item => item.node)
        this.show = false
        this.hideModal('create')
      }).catch(error => {
        messageError(error)
        showToast(this.$t('footerLink.fetchError'), 2, this)
        this.hideModal('create')
      })
    },
    confirmDeleteFooter(footer) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('footerLink.deleteConfirm', { footer: footer.label }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('dataGeneric.delete'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.deleteFooter(footer)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    deleteFooter(footer) {
      axios
        .post('', {
          query: `
            mutation{
                deleteFooterLink(id:"${footer.id}"){
                  found
                }
            }`,
        })
        .then(response => {
          messageError(response, this)
          if (isEmpty(response.data.errors)) {
            this.getFooterLinks()
            showToast(this.$t('footerLink.deleteSuccess'), 1, this)
          }
        })
        .catch(() => {
          showToast(this.$t('footerLink.deleteError'), 2, this)
        })
    },
    editFooter(value) {
      this.selectedFooter = value
      this.openModal('edit')
    },
    previewFooter(value) {
      this.previewBody = value
      this.openModal('preview')
    },
    openModal(ref) {
      switch (ref) {
        case 'preview':
          this.$refs['preview-modal'].show()
          break
        case 'create':
          this.selectedFooter = null
          this.$refs['create-footer-modal'].show()
          break
        case 'edit':
          this.$refs['create-footer-modal'].show()
          break
        default:
          break
      }
    },
    hideModal(ref) {
      switch (ref) {
        case 'preview':
          this.$refs['preview-modal'].hide()
          break
        case 'create':
          this.$refs['create-footer-modal'].hide()
          this.selectedFooter = null
          break
        default:
          break
      }
    },
    removeMailto(url) {
      return url.startsWith('mailto:') ? url.slice(7) : url
    },
    getFooterType(type, url) {
      switch (type) {
        case 'EXT':
          return url.startsWith('mailto:') ? this.$t('labelEmail') : this.$t('dataGeneric.external')
        case 'INT':
          return this.$t('dataGeneric.internal')
        default:
          break
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scope>
@import "@core/scss/vue/libs/quill.scss";

#platform-settings .card-body {
  -ms-flex: none;
  flex: none;
}

.ql-container {
  overflow: hidden;
  // overflow-y: scroll;
  height: 400px;
}
</style>
