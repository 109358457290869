<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <b-card
    class="p-1"
    no-body
  >
    <b-card-body>
      <b-form-group
        :label="$t('footerLink.label')+' *'"
        :invalid-feedback="$t('required')"
        :state="labelInput != ''"
        label-for="label-input"
      >
        <b-form-input
          id="label-input"
          v-model="labelInput"
          :placeholder="$t('footerLink.formLabel')"
          required
        />
      </b-form-group>
      <b-form-group>
        <label for="type-footer">{{ $t("footerLink.type") }}
        </label>
        <b-form-select
          id="type-footer"
          v-model="footerType"
          :options="typeOptions"
        />
      </b-form-group>
      <b-form-group>
        <label for="url-footer">{{ getUrlType() }}</label>
        <b-input-group>
          <b-form-input
            id="url-footer"
            v-model="urlInput"
            type="url"
            :placeholder="$t('footerLink.formLink')"
            autocomplete="new-password"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('footerLink.active')">
        <b-form-checkbox
          v-model="isActive"
          switch
        />
      </b-form-group>
    </b-card-body>

    <b-card-body>
      <b-form @submit.prevent="submitFooterLink">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <quill-editor
                id="editor"
                v-model="html"
                :options="quillOptions"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="cancel"
              variant="outline-secondary"
              class="mr-1"
              @click.prevent="closeModal()"
            >
              {{ $t("dataGeneric.cancel") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="labelInput == ''"
            >
              {{ $t("dataGeneric.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormGroup,
  BCard,
  BCardBody,
  BInputGroup,
  BFormCheckbox,
  BFormSelect,
  BForm,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

// eslint-disable-next-line import/no-extraneous-dependencies
import * as Quill from 'quill'

const alignStyle = Quill.import('attributors/style/align')
const backgroundStyle = Quill.import('attributors/style/background')
const colorStyle = Quill.import('attributors/style/color')
const directionStyle = Quill.import('attributors/style/direction')
const fontStyle = Quill.import('attributors/style/font')
const sizeStyle = Quill.import('attributors/style/size')

Quill.register(alignStyle, true)
Quill.register(backgroundStyle, true)
Quill.register(colorStyle, true)
Quill.register(directionStyle, true)
Quill.register(fontStyle, true)
Quill.register(sizeStyle, true)

// import i18n from '@/libs/i18n'
// import axios from '@axios'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BInputGroup,
    BFormCheckbox,
    BFormSelect,
    quillEditor,

  },
  directives: {
    Ripple,
  },
  props: {
    selectedFooterLink: {
      type: Object,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // ['blockquote', 'code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ direction: 'rtl' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        },
        theme: 'snow',
      },
      typeOptions: [
        { value: 'INT', text: this.$t('dataGeneric.internal') },
        { value: 'EXT', text: this.$t('dataGeneric.external') },
        { value: 'EMAIL', text: this.$t('labelEmail') },
      ],
      footerType: 'INT',
      labelInput: '',
      urlInput: '',
      isActive: true,
      footerLink: null,
      html: '',
      userData: getUserData(),
    }
  },
  mounted() {
    this.labelInput = this.selectedFooterLink ? this.selectedFooterLink[`label${this.language}`] : ''
    this.footerType = this.selectedFooterLink ? this.selectedFooterLink.type : 'INT'
    this.urlInput = this.selectedFooterLink ? this.getInitialUrlInput(this.selectedFooterLink.type) : ''
    this.html = this.selectedFooterLink ? this.selectedFooterLink[`html${this.language}`] : ''
    this.isActive = this.selectedFooterLink ? this.selectedFooterLink.isActive : true
    this.footerLink = this.selectedFooterLink
  },
  methods: {
    submitFooterLink(event) {
      event.preventDefault()
      let objectVariables = []
      objectVariables = [
        { title: `label${this.language}`, value: this.labelInput, type: 'String' },
        { title: `html${this.language}`, value: this.html ? this.convertIndentToStyle(this.html) : '', type: 'String' },
        { title: 'type', value: this.footerType === 'EMAIL' ? 'EXT' : this.footerType, type: 'FrontendConfigFooterLinkTypeChoices' },
        { title: 'isActive', value: this.isActive, type: 'Boolean' },
        {
          title: `friendlyUrl${this.language}`,
          value: this.footerType === 'INT' ? this.urlInput : '',
          type: 'String',
        },
        {
          title: `link${this.language}`,
          value: this.footerType !== 'INT' ? this.adMailto(this.footerType, this.urlInput) : '',
          type: 'String',
        },
      ]
      if (this.footerLink === null) {
        objectVariables.push({ title: 'client', value: this.userData.profile.client.id, type: 'ID!' })
      }

      const variables = {}
      let mutation = 'mutation('
      let query = this.footerLink ? `{updateFooterLink(id:"${this.footerLink.id}",input:{\n` : '{createFooterLink(input:{\n'
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        footerLink {
              id
              label
              link
              html
            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.footerLink ? this.$t('footerLink.editSuccess') : this.$t('footerLink.createSuccess'), 1, this)
          this.$emit('save-changes', true)
        })
        .catch(() => {
          showToast(this.footerLink ? this.$t('footerLink.editError') : this.$t('footerLink.createError'), 2, this)
          this.closeModal()
        })
    },
    convertIndentToStyle(text) {
      const regex = /class="ql-indent-(\d)"([^>]*)>/g

      // Reemplazar todas las clases ql-indent-* por el estilo de padding correspondiente
      const newText = text.replace(regex, (match, p1, p2) => {
        // Obtener el número de indentación
        const indentLevel = parseInt(p1, 10)

        // Obtener el estilo existente si lo hay
        let existingStyle = ''
        if (p2.includes('style="')) {
          const [, st] = p2.match(/style="([^"]*)"/)
          existingStyle = st
        }

        // Calcular el valor del padding-left
        const paddingLeft = `${indentLevel * 3}em`

        // Construir el nuevo estilo con el padding-left añadido
        const newStyle = `${existingStyle} padding-left: ${paddingLeft};`
        // Retornar el elemento con el nuevo estilo
        return `class="ql-indent-${indentLevel}" style="${newStyle}">`
      })

      return newText
    },
    closeModal() {
      this.$emit('close-modal')
    },
    getUrlType() {
      switch (this.footerType) {
        case 'EXT':
          return this.$t('footerLink.link')
        case 'INT':
          return this.$t('friendUrl')
        case 'EMAIL':
          return this.$t('labelEmail')
        default:
          break
      }
      return ''
    },
    getInitialUrlInput(type) {
      switch (type) {
        case 'EXT':
          return this.removeMailto(this.selectedFooterLink[`link${this.language}`])
        case 'INT':
          return this.selectedFooterLink[`friendlyUrl${this.language}`]
        default:
          break
      }
      return ''
    },
    adMailto(type, url) {
      return type === 'EMAIL' ? `mailto:${this.urlInput}` : url
    },
    removeMailto(url) {
      if (url.startsWith('mailto:')) {
        this.footerType = 'EMAIL'
        return url.slice(7)
      }
      return url
    },
  },
}
</script>
<style lang="scss" scope>
@import "@core/scss/vue/libs/quill.scss";

</style>
